import { FlattenedOrder, Order } from 'api/order';

export const flattenOrderData = (orders?: Order[]): FlattenedOrder[] => {
	return orders?.map(order => {
		const { company, ...restOrder } = order;

		if (!company) {
			throw new Error(`Order with ID ${order.id} does not have a valid company.`);
		}

		const flattenedCompanyProps = {
			companyAccountNumber: company.accountNumber,
			companyName: company.name,
			companyCustomerNumber: company.customerNumber,
			companySalesPersonCode: company.salesPersonCode,
			companyIsActive: company.isActive,
			companyAddressLine1: company.addressLine1,
			companyAddressLine2: company.addressLine2,
			companyAddressLine3: company.addressLine3,
			companyAddressCity: company.addressCity,
			companyAddressState: company.addressState,
			companyAddressZipCode: company.addressZipCode,
			companyId: company.id,
			companyIsDeleted: company.isDeleted,
			companyCreatedBy: company.createdBy,
			companyCreatedAt: company.createdAt,
			companyUpdatedBy: company.updatedBy,
			companyUpdatedAt: company.updatedAt,
			companyDeletedBy: company.deletedBy,
			companyDeletedAt: company.deletedAt,
			companyCustomerDiscounts: company.customerDiscounts,
			companySalesRepresentative: company.salesRepresentative
		};

		return {
			...restOrder,
			...flattenedCompanyProps
		};
	});
};
