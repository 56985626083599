import React from 'react';
import css from './CustomerServiceQuotesView.module.scss';
import { useAuth } from 'providers/Auth';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { Link } from 'components/Link';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import {
	TableController,
	useTableProperties,
	ICurrentTableValues
} from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { Order, useGetAllActiveOrders } from 'api/order';
import { formatDate } from 'common/utils/formatDate';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { searchTableColumns } from 'common/utils/searchTableColumns';
import { flattenOrderData } from 'common/utils/flattenOrderData';

export const CustomerServiceQuotesView = () => {
	const tableBaseRoute = '/cs/quotes/';
	const { isLoading: isAuthLoading } = useAuth();
	const [tableProperties, setTableProperties] = useTableProperties(tableBaseRoute);

	// LOADING...
	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	const headers: TTableHeader[] = [
		{
			label: 'Confirmation Number',
			property: 'orderConfirmationId',
			className: css.smallColumn
		},
		{
			label: 'Order Type',
			property: 'orderType'
		},
		{
			label: 'PO Number',
			property: 'poNumber'
		},
		{
			label: 'Customer Name',
			property: 'companyName'
		},
		{
			label: 'Account Number',
			property: 'companyAccountNumber'
		},
		{
			label: 'Date Created',
			property: 'createdAt',
			renderFunction: (data: Order) => formatDate(data.createdAt, true, true)
		},
		{
			label: 'Total',
			property: 'orderTotal',
			renderFunction: (data: Order) => (data.orderTotal ? formatAsUSD(data.orderTotal, true) : '')
		},
		{
			label: 'Manage',
			property: 'manage',
			renderFunction: (data: Order) => <Link to={`/cs/quotes/quote/${data.id}`}>Manage</Link>
		}
	];

	//#region Active Table Objects
	const useQuery = (): UseQueryResult => {
		return useGetAllActiveOrders('Submitted', null);
	};

	const dataSelector = (tableValues: ICurrentTableValues) => (orders: Order[]) => {
		// This view only shows unclaimed quotes
		const flatOrderData = flattenOrderData(orders);
		return flatOrderData?.filter(order => {
			if (order.claimedById) {
				return false;
			}

			if (tableValues.searchQuery) {
				return searchTableColumns(tableValues.searchQuery, order, headers);
			}

			return true;
		});
	};

	return (
		<>
			<PageFrame title={'New Quotes'} HTMLTitle={'New Quotes'}>
				<Section>
					<Container>
						<Panel title={''} hasPadding={false}>
							<TableController
								headers={headers}
								baseRoute={tableBaseRoute}
								itemsPerPage={10}
								useQuery={useQuery}
								isUrlUpdated={true}
								hasPagination={true}
								emptyMessage={'No quotes found!'}
								dataSelector={dataSelector(tableProperties)}
								setCurrentTableValues={setTableProperties}
								totalCountSelector={(data: any) => data?.length}
								hasSearch={true}
								searchPlaceholder={'Search new quotes'}
								tableHandlesPaginatingData={true}
							/>
						</Panel>
					</Container>
				</Section>
			</PageFrame>
		</>
	);
};
