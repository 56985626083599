import React, { useState } from 'react';
import css from './CustomerServiceCompletedOrdersView.module.scss';
import { useLocation } from 'react-router';
import { useAuth } from 'providers/Auth';
import queryString from 'query-string';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { Link } from 'components/Link';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import {
	TableController,
	useTableProperties,
	ICurrentTableValues
} from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { Order, useGetAllCompletedOrders } from 'api/order';
import { useGetUsersByRole } from 'api/user';
import { formatDate } from 'common/utils/formatDate';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { Select } from 'components/Form/Select/Select';
import { searchTableColumns } from 'common/utils/searchTableColumns';
import { flattenOrderData } from 'common/utils/flattenOrderData';

type TCSOrderFilterOptions = {
	customerServiceRepUserId?: string;
};

export const CustomerServiceCompletedOrdersView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const location = useLocation();
	const filtersSetByRouter = queryString.parse(location?.search);
	const { data: customerServiceUsersData } = useGetUsersByRole('Customer Service');

	const [filterOptions, setFilterOptions] = useState<TCSOrderFilterOptions>({
		...filtersSetByRouter
	});

	const tableBaseRoute = '/cs/orders/completed/';
	const [tableProperties, setTableProperties] = useTableProperties(tableBaseRoute);

	const useQuery = (): UseQueryResult => {
		const customerServiceRepUserId = filterOptions.customerServiceRepUserId
			? filterOptions.customerServiceRepUserId
			: null;

		return useGetAllCompletedOrders('OrderPlaced', customerServiceRepUserId);
	};

	const dataSelector = (tableValues: ICurrentTableValues) => (orders: Order[]) => {
		const flatOrderData = flattenOrderData(orders);

		return flatOrderData?.filter(order => {
			if (tableValues.searchQuery) {
				return searchTableColumns(tableValues.searchQuery, order, headers);
			}

			return true;
		});
	};
	const headers: TTableHeader[] = [
		{
			label: 'Confirmation Number',
			property: 'orderConfirmationId',
			className: css.smallColumn
		},
		{
			label: 'Order Type',
			property: 'orderType'
		},
		{
			label: 'PO Number',
			property: 'poNumber'
		},
		{
			label: 'Customer Name',
			property: 'companyName'
		},
		{
			label: 'Account Number',
			property: 'companyAccountNumber'
		},
		{
			label: 'Order Date',
			property: 'submittedOn',
			renderFunction: (data: Order) =>
				data?.submittedOn
					? formatDate(data.submittedOn, true, true)
					: formatDate(data.createdAt, true, true)
		},
		{
			label: 'Claimed By',
			property: 'claimedByUser.firstName',
			renderFunction: (data: Order) =>
				data?.claimedByUser
					? data?.claimedByUser?.firstName + ' ' + data?.claimedByUser?.lastName
					: 'Unclaimed'
		},
		{
			label: 'Total',
			property: 'orderTotal',
			renderFunction: (data: Order) => (data.orderTotal ? formatAsUSD(data.orderTotal, true) : '')
		},
		{
			label: 'View',
			property: 'view',
			renderFunction: (data: Order) => <Link to={`/cs/orders/completed/order/${data.id}`}>View</Link>
		}
	];

	// LOADING...
	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			<PageFrame title={'Orders'} HTMLTitle={'Dashboard'}>
				<Section>
					<Container>
						<Panel title={''} hasPadding={false}>
							<TableController
								headers={headers}
								baseRoute={tableBaseRoute}
								itemsPerPage={10}
								useQuery={useQuery}
								isUrlUpdated={true}
								hasPagination={true}
								emptyMessage={'No orders found!'}
								dataSelector={dataSelector(tableProperties)}
								setCurrentTableValues={setTableProperties}
								totalCountSelector={(data: any) => data?.length}
								hasSearch={true}
								searchPlaceholder={'Search completed orders'}
								tableHandlesPaginatingData={true}
								queryStringObject={filterOptions}
								additionalActions={[
									<div className={css.displayFlex}>
										{/* CLAIMED BY USER */}
										<div>
											<Select
												label=''
												isLabelVisible={false}
												name='tableFilterClaimedByUser'
												onChange={(event: any) => {
													setFilterOptions({
														...filterOptions,
														customerServiceRepUserId: event.target.value
													});
												}}
												value={filterOptions.customerServiceRepUserId}
												className={css.customerServiceUserFilter}
											>
												<option value=''>All</option>
												{customerServiceUsersData?.map(customerServiceUser => (
													<option value={customerServiceUser.userId} key={customerServiceUser.userId}>
														{customerServiceUser.firstName} {customerServiceUser.lastName}
													</option>
												))}
											</Select>
										</div>
									</div>
								]}
							/>
						</Panel>
					</Container>
				</Section>
			</PageFrame>
		</>
	);
};
